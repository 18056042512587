.App {
  text-align: center;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', 'Roboto', sans-serif;
}


img.spreader {
    max-width: 100%;
    margin-top: 50px;
    margin-bottom: 15px;
}

#root {
    margin: 0;
    min-height: 100vh;
    position: relative;
}

#footer {
    position: absolute;
    bottom: 0;
    width: 100%
}

html {
    font-size: 10px;
    height: 100%;
}

td, th {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 1.5rem;
    justify-content: center;
    text-align: center;
    padding: 2px 15px;
    margin: 0px;
}

th {
    font-weight: 400;
    border-bottom: 1px solid #555555;
}

td.custom-price {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 1.8rem;
    justify-content: left;
    text-align: left;
    padding: 1px 15px 0 0;
    margin: 0;
    width: 10rem;
    height: 3rem;
}

td.custom-price-label {
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    font-size: 1.3rem;
    justify-content: left;
    text-align: left;
    padding: 2px 15px 1px 0;
    border-top: solid  0.5px #555555;
    margin: 0px;
}

tbody tr:nth-child(even) {
    background: #f5f5f5;
}
